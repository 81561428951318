<template>
  <div :class="$style.document">
    <div :class="$style.wrapper">
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm"
      >
        Сохранить контент
      </el-button>
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-width="10rem">
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" type="textarea" />
      </el-form-item>
      <el-form-item label="Тип" prop="type">
        <el-select v-model="form.type" placeholder="Выберите">
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Документ" prop="files">
        <Uploader
          :limit="1"
          :files="form.files"
          :is-caption-disabled="true"
          :is-show-image="false"
          @upload="uploadFiles"
        />
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number
          v-model.number="form.orderField"
          controls-position="right"
          :min="0"
        >
        </el-input-number>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import { DOCUMENT_TYPES } from '@/constants/content/documents'
export default {
  components: { Uploader },
  mixins: [notifications],
  data() {
    return {
      form: {
        name: '',
        files: [],
        orderField: 0,
        type: '',
      },
      types: DOCUMENT_TYPES,
      rules: {
        files: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
      },
    }
  },
  async created() {
    await this.getDocument()
  },
  methods: {
    async getDocument() {
      const { value, error } =
        await delivery.ContentServiceCore.DocumentsActions.getById(
          this.$route.params.id,
        )

      if (error) return

      this.form = {
        name: value.name,
        type: value.type,
        orderField: value.orderField,
        files: [
          {
            original: value.original,
            caption: value.original.substring(
              value.original.lastIndexOf('/') + 1,
            ),
          },
        ],
      }
    },
    uploadFiles(files) {
      this.form.files = files
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = {
            name: this.form.name,
            orderField: this.form.orderField,
            original: this.form.files[0].original,
            type: this.form.type,
          }

          const { error } =
            await delivery.ContentServiceCore.DocumentsActions.update(
              this.$route.params.id,
              data,
            )
          loading.close()

          if (error) return

          this.showNotification('Документ успешно редактирован', 'success')
          this.$router.push('/addwine/content/documents')
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.document {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
}
</style>
